.designer-container {
    font-size: 14px;

    .nav-item {
        margin-left: 1em;
    }

    .workflow-designer-wrapper {
        height: 100%;
    }
}

.executed-activity {
    background-color: #6faa44;
    color: #ffffff;
}

.blocked-activity {
    background-color: #5a8fee;
    color: #ffffff;
}

.faulted-activity {
    background-color: #d23c3c;
    color: #ffffff;
}

.waiting-activity {
    background-color: #c8ced3;
    color: #ffffff;
}

.highlight-activity {
    background-color: #172b4d !important;
    border-color: #172b4d !important;
    color: #ffffff !important;
}

wf-activity-editor {
    cursor: auto;
}

wf-action-btn {
    min-height: 2.2rem;
    margin: 10px 0px;
}